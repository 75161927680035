<template>
  <div
    class="mats-listing__wrapper"
  >
    <div style="display: flex; justify-content: flex-end;margin-top: 4px">
      <button
        v-b-modal.return-to-stock
        class="editButton"
        :disabled="selectedArticles.length === 0"
        @click="openReturnModal"
      >
        {{ $t('Return') }}
      </button>
    </div>
    <div style="width: 100%; padding-top: 6px; margin-top: 0px;">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th style="display: flex;gap: 5px">
              <input
                type="checkbox"
                :checked="allSelected"
                @change="selectAll($event)"
              >
              {{ $t('Select') }}
            </th>
            <th>
              {{ $t('ArticleName') }}
            </th>
            <th>
              {{ $t('Team') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in getArticleOrders"
            :key="item.articleNumber"
          >
            <td>
              <input
                type="checkbox"
                :value="item.articleNumber"
                :checked="selectedArticles.includes(item.orderItemId)"
                @change="toggleSelection(item.orderItemId)"
              >
            </td><td>
              {{ item.articleNumber }}
            </td>
            <td>
              {{ item.teamName }}
            </td>
          </tr>
        </tbody>
      </table>
      <ReturnArticleToStock
        :article-number="selectedArticles"
        :sales-user-id="salesUserId"
        @submitForm="sentToStock"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'
import ReturnArticleToStock from './modals/ReturnArticleToStock.vue'


export default {
  components: {
    ReturnArticleToStock,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    salesUserId: {
      type: String,
      required: true,
    },
    pageNumberStock: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 15,
      selectedArticles: [],
    }
  },
  computed: {
    ...mapGetters(['getArticleOrders']),
    allSelected() {
      return (
        this.getArticleOrders.length > 0
        && this.selectedArticles.length === this.getArticleOrders.length
      );
    },
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    ...mapActions(['resetReadOrderItems', 'returnOrder', 'loadDeliveredOrders']),
    toggleSelection(orderItemId) {
      const index = this.selectedArticles.indexOf(orderItemId);
      if (index > -1) {
        this.selectedArticles.splice(index, 1);
      } else {
        this.selectedArticles.push(orderItemId);
      }
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedArticles = this.getArticleOrders.map(
          (item) => { return item.orderItemId },
        );
      } else {
        this.selectedArticles = [];
      }
    },
    openReturnModal() {
      this.$refs.returnModal.show();
    },
    sentToStock(value) {
      this.returnOrder({
        object: value,
        successCallback: () => {
          this.selectedArticles = [];
          this.resetReadOrderItems()
          this.loadDeliveredOrders({
            orderNumber: null,
            year: moment().format('YYYY'),
            clientId: null,
            pageNumber: this.pageNumberStock,
            pageSize: 15,
            salesType: 'Product',
          })
        },
      });
    },
  },
}
</script>

<style scoped lang="scss">

.team_table td {
  padding: 12px 9px !important;
}

.team_table th {
  // text-align: center;
}

@media screen and (max-width: 1000px) {
.mats-listing__wrapper {
  margin-top: 0px;
  width: 100%;
}
}
</style>
