<template>
  <div class="main no-flex">
    <b-tabs v-model="tabIndex">
      <b-tab
        style="  display:flex; gap:15px; width:100%"
      >
        <template #title>
          <strong
            style="font-weight: 500; color: black"
          >{{ $t('ReturnProcess') }}</strong>
        </template>
        <div
          v-if="tabIndex == 0"
          class="tab_return"
        >
          <ReturnProcess
            @setSalesPerson="handleSetSalesPerson"
            @pageNumber="newPageNumber"
          />
          <ReturnProcessToStock
            :page-number-stock="pageNumberStock"
            :sales-user-id="salesUserId"
          />
        </div>
      </b-tab>
      <b-tab
        style="display:flex;gap:15px;width:100%"
      >
        <template #title>
          <strong
            style="font-weight: 500; color: black"
          >{{ $t('ReturnReporting') }}</strong>
        </template>
        <div
          v-if="tabIndex == 1"
        >
          <ReturnReporting />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ReturnProcess from '@/components/orders/ReturnProcess.vue'
import ReturnProcessToStock from '@/components/orders/ReturnProcessToStock.vue'
import ReturnReporting from '@/components/orders/ReturnReporting.vue';

export default {
  components: {
    ReturnProcess,
    ReturnProcessToStock,
    ReturnReporting,
  },
  data() {
    return {
      salesUserId: '',
      tabIndex: 0,
      pageNumberStock: 1,
    };
  },
  methods: {
    handleSetSalesPerson(salesUserId) {
      this.salesUserId = salesUserId;
    },
    newPageNumber(value) {
      this.pageNumberStock = value
    },
  },
}


</script>
<style>
.mainn{
  padding: 75px 15px 15px 120px;
  display: flex;
  gap: 30px;
}

.tab_return {
display:flex;gap:15px
}

@media screen and (max-width: 1000px) {
  .main {
    padding: 75px 15px 15px 20px;
  }
  .tab_return {
    display: flex;
    flex-direction: column;
  }
}

</style>
